/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
@tailwind base;
@tailwind components;
@tailwind utilities;
html,
body {
  font-family: "Archivo", Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.4;
}

img {
  width: 100%;
}

a {
  color: #6c757d;
}

a:hover {
  color: #fec503;
  text-decoration: none;
}

::selection {
  background: #fec503;
  text-shadow: none;
}

footer {
  padding: 2rem 0;
  background-color: #0d001f;
}

.footer-column:not(:first-child) {
  padding-top: 2rem;
}
@media (min-width: 768px) {
  .footer-column:not(:first-child) {
    padding-top: 0rem;
  }
}

.footer-column {
  text-align: center;
}
.footer-column .nav-item .nav-link {
  padding: 0.7rem 0;
}
.footer-column .nav-item span.nav-link {
  color: #6c757d;
}
.footer-column .nav-item span.footer-title {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
}
.footer-column .nav-item .fas {
  margin-right: 0.5rem;
}
.footer-column ul {
  display: inline-block;
}
@media (min-width: 768px) {
  .footer-column ul {
    text-align: left;
  }
}

ul.social-buttons {
  margin-bottom: 0;
}

ul.social-buttons li a:active,
ul.social-buttons li a:focus,
ul.social-buttons li a:hover {
  background-color: #fec503;
}

ul.social-buttons li a {
  font-size: 20px;
  line-height: 40px;
  display: block;
  width: 40px;
  height: 40px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  color: #fff;
  border-radius: 100%;
  outline: 0;
  background-color: #1a1d20;
}

footer .quick-links {
  font-size: 90%;
  line-height: 40px;
  margin-bottom: 0;
  text-transform: none;
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.copyright {
  color: white;
}

.fa-ellipsis-h {
  color: white;
  padding: 2rem 0;
}

ul > li > a {
  color: #fff !important;
}

ul > li > a:hover {
  text-decoration: underline !important;
  color: #0adda0 !important;
}

nav > ul > li > div > a:hover {
  text-decoration: none !important;
  border-bottom: 1px solid #0adda0 !important;
}

ul > li > span {
  color: #fff !important;
}

.social-btn-custom {
  display: flex;
  align-items: center;
}

.bradcum-max-width {
  max-width: 1200px !important;
}

nav > ol > li > a {
  color: #0d001f;
  font-size: 0.875rem;
  font-weight: 700;
  border: 0;
  text-decoration: none;
}

nav > ol > li > a:hover {
  border-bottom: solid 2px #4d13d1;
  color: rgba(77, 19, 209, 0.5);
  text-decoration: none;
}