/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
// html, body { height: 100%; }
// // body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
// body { margin: 0; font-family: "Archivo",Helvetica,Arial,sans-serif; }
html,
body {
  font-family: "Archivo", Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.4;
}

img {
  width: 100%;
}

$link-light-grey: #6c757d;
$primary-orange: #fec503; //before: fed136
$footer-background-light-grey: #0d001f;
$footer-background-grey: #1a1d20;
$social-buttons-circle-dark-grey: white;

a {
  color: $link-light-grey;
}
a:hover {
  color: $primary-orange;
  text-decoration: none;
}

::selection {
  background: $primary-orange;
  text-shadow: none;
}

footer {
  padding: 2rem 0;
  background-color: #0d001f;
}

.footer-column:not(:first-child) {
  padding-top: 2rem;
  @media (min-width: 768px) {
    padding-top: 0rem;
  }
}

.footer-column {
  text-align: center;
  //border: red solid 1px;
  .nav-item {
    .nav-link {
      padding: 0.7rem 0;
    }
    span.nav-link {
      color: $link-light-grey;
    }
    span.footer-title {
      font-size: 14px;
      font-weight: 700;
      color: #fff;
      text-transform: uppercase;
    }
    .fas {
      margin-right: 0.5rem;
    }
  }
  ul {
    display: inline-block;
    @media (min-width: 768px) {
      text-align: left;
    }
    //border: yellow solid 1px;
  }
}

ul.social-buttons {
  margin-bottom: 0;
}

ul.social-buttons li a:active,
ul.social-buttons li a:focus,
ul.social-buttons li a:hover {
  background-color: $primary-orange;
}

ul.social-buttons li a {
  font-size: 20px;
  line-height: 40px;
  display: block;
  width: 40px;
  height: 40px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  color: #fff;
  border-radius: 100%;
  outline: 0;
  background-color: $footer-background-grey;
}

footer .quick-links {
  font-size: 90%;
  line-height: 40px;
  margin-bottom: 0;
  text-transform: none;
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.copyright {
  color: white;
}

.fa-ellipsis-h {
  color: white;
  padding: 2rem 0;
}

ul > li > a {
  color: #fff !important;
}

ul > li > a:hover {
  text-decoration: underline !important;
  color: #0adda0 !important;
}

nav > ul > li > div > a:hover {
  text-decoration: none !important;
  border-bottom: 1px solid #0adda0 !important;
}

ul > li > span {
  color: #fff !important;
}

.social-btn-custom {
  display: flex;
  align-items: center;
}

.bradcum-max-width {
  max-width: 1200px !important;
}

// /* Breadcrumbs */
// .breadcrumb {
//   font-size: 14px;
//   font-family: "Nunito", sans-serif;
//   color: #899bbd;
//   font-weight: 600;
// }
// .breadcrumb a {
//   color: #899bbd;
//   transition: 0.3s;
// }
// .breadcrumb a:hover {
//   color: #51678f;
// }
// .breadcrumb .breadcrumb-item::before {
//   color: #899bbd;
// }
// .breadcrumb .active {
//   color: #42619b;
//   font-weight: 600;
// }
nav > ol > li > a {
  color: #0d001f;
  font-size: .875rem;
  font-weight: 700;
  border: 0;
  text-decoration: none;
}

nav > ol > li > a:hover {
  border-bottom: solid 2px #4d13d1;
  color: rgba(77,19,209,0.5);
  text-decoration: none;
}